import {
  Button,
  Checkbox,
  Column,
  Row,
  Spinner,
  Text,
  TextField,
} from "@yolaw/ui-kit-components";
import {
  Form,
  FormFooter,
  FormSection,
  FormSectionBody,
  OptinField,
} from "components/FormStyles";
import UserInformationFieldset, {
  TUserInformationInputs,
} from "components/UserInformationFieldset";
import { useApp, useSegment, useUser } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import { useCallback, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import { AuthService, UserService } from "services";
import { BuiltRoutePath } from "services/router";
import { css, styled } from "styled-components";
import { ERROR_MESSAGES } from "utils/constants";
import { FORM_ERROR_MESSAGES } from "utils/form";

const LinkButton = styled(Row)`
  ${({ theme }) => css`
    && {
      align-items: center;
      justify-content: flex-start;
    }

    > button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 0.1px;
      color: ${theme.colors.secondary.main};
      align-self: flex-start;
      padding: unset;

      &:disabled {
        color: ${theme.colors.secondary.lighter};
        cursor: unset;
      }
    }
  `}
`;

const EmailFieldWrapper = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxs}px;
    }
  `};
`;

type FormInputs = TUserInformationInputs & {
  emailOptIn: boolean;
};

const UserProfileUpdateForm = () => {
  const app = useApp();
  const user = useUser();
  const segment = useSegment();

  const { navigateTo } = useAppRoutes();

  const [isRequestingEmailChange, setIsRequestingEmailChange] = useState(false);

  const formMethods = useForm<FormInputs>({
    mode: "onTouched",
    values: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      phoneNumber: user?.phone || "",
      email: user?.email || "",
      emailOptIn: !!user?.ok_for_emails,
    },
  });

  const { control, handleSubmit, formState } = formMethods;

  const { isDirty, isSubmitting } = formState;

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    segment.track("user information: updated");

    const newUserData = await AuthService.patchUserProfile({
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      ok_for_emails: data.emailOptIn,
    });

    if (newUserData) {
      app.setUserInfo(newUserData);

      toast.success("Votre profil a été mis à jour avec succès.");
    }
  };

  const requestEmailChange = useCallback(async () => {
    try {
      setIsRequestingEmailChange(true);
      await UserService.changeEmailInitiate();
      setIsRequestingEmailChange(false);

      navigateTo(BuiltRoutePath.UserEmailChangeInitiateRequestSentPage);
    } catch (error: any) {
      toast.error(error.message || ERROR_MESSAGES.DEFAULT);
    }
  }, [navigateTo]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <FormSectionBody>
            <UserInformationFieldset
              showEmail={false}
              validationRules={{
                firstName: {
                  required:
                    !!user?.first_name && FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
                lastName: {
                  required:
                    !!user?.last_name && FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
                phoneNumber: {
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
              }}
            />

            <EmailFieldWrapper>
              <TextField disabled label="Email" showLabel value={user.email} />
              <LinkButton>
                <button
                  type="button"
                  onClick={requestEmailChange}
                  disabled={isRequestingEmailChange}
                >
                  Modifier mon email
                </button>
                {isRequestingEmailChange && <Spinner size={16} />}
              </LinkButton>
            </EmailFieldWrapper>

            <OptinField>
              <Controller
                control={control}
                name="emailOptIn"
                render={({ field: { ref, value, ...restOfField } }) => (
                  <Checkbox
                    {...restOfField}
                    id="emailOptIn"
                    checked={value}
                    value={JSON.stringify(value)}
                  />
                )}
              />
              <label htmlFor="emailOptIn">
                <Text type="small">
                  J'accepte que{" "}
                  <strong>
                    Legalstart.fr m'envoie des informations sur des promotions
                    ou des services fournis par Legalstart.fr
                  </strong>{" "}
                  (promis, que des informations utiles, pas de spam !)
                </Text>
              </label>
            </OptinField>

            <FormFooter>
              <Button
                type="submit"
                variant="primary"
                text="Mettre à jour"
                disabled={!isDirty || isSubmitting}
                isLoading={isSubmitting}
              />
            </FormFooter>
          </FormSectionBody>
        </FormSection>
      </Form>
    </FormProvider>
  );
};

export default UserProfileUpdateForm;
