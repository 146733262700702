import { Column } from "@yolaw/ui-kit-components";
import { LegalStartLogo } from "@yolaw/ui-kit-icons";
import { useApp, useIsMobile } from "hooks";
import { BackButton } from "pages/components";
import { Outlet } from "react-router-dom";
import styled, { css } from "styled-components";

const MainContainer = styled.div`
  container-type: inline-size;
  min-height: 100vh;

  ${({ theme }) => css`
    background-color: ${theme.colors.primary.lighter};
  `}
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  ${({ theme }) => css`
    a {
      color: ${theme.colors.primary.main};
      font-weight: bold;
      cursor: pointer;
    }

    @container (max-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.s}px ${theme.spacing.xs}px;
      width: 100%;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      padding: ${theme.spacing.l}px;
    }
  `};
`;

const FormContainer = styled(Column)`
  align-items: center;
  max-width: 560px;
  ${({ theme }) => css`
    background-color: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.medium};
    && {
      row-gap: ${theme.spacing.m}px;
    }

    @container (max-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.l}px ${theme.spacing.s}px;
      width: 100%;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      padding: ${theme.spacing.l}px;
    }
  `};
`;

const TopBar = styled.header`
  position: sticky;
  top: 0;

  display: flex;
  background-color: white;
  width: 100%;

  > * {
    flex: 1;
  }

  ${({ theme }) => css`
    border-bottom: 0.75px solid ${theme.colors.primary.light};

    @container (max-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.xxs}px ${theme.spacing.xs}px;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      padding: ${theme.spacing.xxs}px ${theme.spacing.l}px;
    }
  `};
`;

const StyledBackButton = styled(BackButton)`
  padding: unset;
  align-self: unset;
`;

type AuthenticationLayoutProps = {
  /** Display the header/topbar and hide the logo in the form */
  showHeader?: boolean;
};

const AuthenticationLayout = ({ showHeader }: AuthenticationLayoutProps) => {
  const app = useApp();
  const isMobile = useIsMobile();

  const isBackable = !!app.navigation.headerBackTo;
  const backButtonConf = isBackable
    ? {
        label: "Retour",
        to: app.navigation.headerBackTo,
      }
    : {
        label: "Retour à l'accueil",
        to: process.env.REACT_APP_LEGALSTART_CMS_URL,
      };

  return (
    <MainContainer>
      {showHeader && (
        <TopBar>
          <StyledBackButton
            label={isMobile ? "" : backButtonConf.label}
            to={backButtonConf.to}
            replace
          />
          <LegalStartLogo height={32} />
          <div />
        </TopBar>
      )}
      <PageContainer>
        <FormContainer>
          {!showHeader && <LegalStartLogo width={270} height={56} />}
          <Outlet />
        </FormContainer>
      </PageContainer>
    </MainContainer>
  );
};

export default AuthenticationLayout;
