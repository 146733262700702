import {
  Button,
  Column,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import { useIsMobile } from "hooks";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UserService } from "services";
import { BuiltRoutePath } from "services/router";
import { EmailUtils } from "utils";
import { ERROR_MESSAGES } from "utils/constants";
import { getNextParam } from "../auth-utils";
import {
  FormInnerContainer,
  FormFooterActionContainer,
} from "../components/misc-components";

type FormInputs = {
  email: string;
};

const EmailModificationPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const formMethods = useForm<FormInputs>({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
  });
  const { control, formState, handleSubmit, setError } = formMethods;
  const { errors, isSubmitting } = formState;

  const onSubmit = async (data: FormInputs) => {
    if (!data.email) return;

    try {
      await UserService.updateInitialEmail({
        email: data.email,
        next: getNextParam(),
      });

      // Update search params with the new email to use in the verification page
      searchParams.set("new_email", data.email);

      navigate(
        BuiltRoutePath.EmailVerificationPage + `?${searchParams.toString()}`,
        {
          replace: true,
          state: { disableInitialRequest: true },
        }
      );
    } catch (error: any) {
      setError("email", { message: error.message || ERROR_MESSAGES.DEFAULT });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInnerContainer>
        <Column>
          <Title
            text="Modifiez votre email"
            type={isMobile ? "H1" : "H3"}
            color="secondary.main"
          />
          <Text>
            Renseignez la nouvelle adresse email avec laquelle vous souhaitez
            vous connecter à votre compte.
          </Text>
          <Controller
            control={control}
            name="email"
            rules={EmailUtils.emailFieldValidationRulesRHF}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                type="email"
                placeholder="Entrez votre email"
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                {...restOfField}
              />
            )}
          />
        </Column>

        <FormFooterActionContainer>
          <Link
            to={BuiltRoutePath.EmailVerificationPage + window.location.search}
            replace
            state={{ disableInitialRequest: true, disableCounter: true }}
          >
            Annuler
          </Link>

          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Modifier l'email
          </Button>
        </FormFooterActionContainer>
      </FormInnerContainer>
    </form>
  );
};

export default EmailModificationPage;
