import * as Sentry from "@sentry/react";
import { defaultTheme } from "@yolaw/ui-kit-components";
import { useCallback, useEffect, useReducer } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

import LSToastContainer from "components/LSToastContainer";
import { AppContext, FormalitiesContext } from "contexts";
import { AppLayout, AuthenticationLayout, GlobalLayout } from "layout";
import {
  AuthenticationErrorPage,
  ComptastartSellingPage,
  ContactFormalistPage,
  ContactLawyerPage,
  ContactTechnicalSupportPage,
  UserChangeEmailInitiateRequestSentPage,
  EmailModificationPage,
  EmailVerificationPage,
  ErrorPage,
  FormalitiesPage,
  InvoicesPage,
  LegalstartAssistantPage,
  LoadingPage,
  LoginPage,
  LSComptaBasicPage,
  LSCompteProWaitingForSirenPage,
  OtherServicesPage,
  PasswordResetCollectEmailPage,
  PasswordResetPage,
  PasswordResetRequestPage,
  RegistriesApp,
  SetPasswordPage,
  SignUpPage,
  UserChangeEmailConfirmPage,
  UserProfilePage,
  ZenApp,
  UserChangeEmailConfirmRequestSentPage,
} from "pages";
import SubPageLayout from "pages/legalstart-assistant/SubPageLayout";
import { DecisionsContext } from "pages/zen/pages/mandatory-records";
import {
  ApiService,
  AuthService,
  ConfigService,
  FormalityService,
  LegacySubscriptionService,
  SubscriptionsService,
} from "services";
import {
  BuiltRoutePath,
  getLegalEntityIdInURL,
  LegalEntityOwnershipChecker,
  NavigateToLegalEntityBasePath,
  PathTemplate,
  RoutePath,
} from "services/router";
import { CookiesUtils } from "utils";
import { ERROR_MESSAGES } from "utils/constants";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const authenticatedRouter = sentryCreateBrowserRouter([
  {
    path: "*",
    element: <ErrorPage customMessage="Page non trouvée !" />,
  },
  // If no path is provided, redirect to the formalities page
  {
    index: true,
    element: <Navigate to={BuiltRoutePath.FormalitiesPage} replace />,
  },
  // Authenticated user should not be able to access login page
  {
    path: RoutePath.LoginPage,
    element: <Navigate to={"/"} replace />,
  },
  {
    // TODO: check condition to access these pages
    element: <AuthenticationLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.AuthenticationErrorPage,
        element: <AuthenticationErrorPage />,
      },
      {
        path: RoutePath.SetPasswordPage,
        element: <SetPasswordPage />,
      },
      {
        path: RoutePath.EmailModificationPage,
        element: <EmailModificationPage />,
      },
      {
        path: RoutePath.EmailVerificationPage,
        element: <EmailVerificationPage />,
      },
    ],
  },
  {
    element: <AuthenticationLayout showHeader />,
    errorElement: <ErrorPage />,
    children: [
      // Change email flow pages - START
      {
        path: `${RoutePath.UserProfilePage}/*`,
        children: [
          {
            path: RoutePath.UserEmailChangeInitiateRequestSentPage,
            element: <UserChangeEmailInitiateRequestSentPage />,
          },
          {
            path: RoutePath.UserEmailChangeConfirmPage,
            element: <UserChangeEmailConfirmPage />,
          },
          {
            path: RoutePath.UserEmailChangeConfirmRequestSentPage,
            element: <UserChangeEmailConfirmRequestSentPage />,
          },
        ],
      },
      // Change email flow pages - END
    ],
  },
  {
    element: <GlobalLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <AppLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: RoutePath.FormalitiesPage,
            element: <FormalitiesPage />,
          },
          {
            path: RoutePath.LegalAssistancePage,
            element: <LegalstartAssistantPage />,
          },
          {
            path: RoutePath.OtherServicesPage,
            element: <OtherServicesPage />,
          },
          {
            path: RoutePath.UserProfilePage,
            element: <UserProfilePage />,
          },
          {
            path: RoutePath.InvoicesPage,
            element: <InvoicesPage />,
          },
          {
            path: `${PathTemplate.LegalEntityBase}/*`,
            element: <LegalEntityOwnershipChecker />,
            children: [
              {
                index: true,
                path: "*",
                element: <Navigate to="/" replace />,
              },
              // LS Compte Pro - START
              {
                path: RoutePath.LSCPProAccount,
                element: <LSCompteProWaitingForSirenPage />,
              },
              {
                path: RoutePath.LSCPMyExpenses,
                element: <LSCompteProWaitingForSirenPage />,
              },
              {
                path: RoutePath.LSCPMyLatestTransactions,
                element: <LSCompteProWaitingForSirenPage />,
              },
              {
                path: RoutePath.LSCPGenerateInvoice,
                element: <LSCompteProWaitingForSirenPage />,
              },
              // LS Compte Pro - END
              {
                path: RoutePath.ComptastartPage,
                element: <ComptastartSellingPage />,
              },
              {
                path: RoutePath.LSComptaBasicPage,
                element: <LSComptaBasicPage />,
              },
              {
                path: `${RoutePath.Zen}/*`,
                element: <ZenApp />,
              },
              {
                path: `${RoutePath.Registries}/*`,
                element: <RegistriesApp />,
              },
            ],
          },
          // Backward compatible - START
          {
            path: RoutePath.ComptastartPage,
            element: <NavigateToLegalEntityBasePath />,
          },
          {
            path: RoutePath.LSComptaBasicPage,
            element: <NavigateToLegalEntityBasePath />,
          },
          {
            path: `${RoutePath.Zen}/*`,
            element: <NavigateToLegalEntityBasePath />,
          },
          {
            path: `${RoutePath.Registries}/*`,
            element: <NavigateToLegalEntityBasePath />,
          },
          // Backward compatible - END
        ],
      },
      {
        path: RoutePath.LegalAssistancePage,
        element: <AppLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            element: <SubPageLayout />,
            children: [
              {
                path: RoutePath.ContactFormalistPage,
                element: <ContactFormalistPage />,
              },
              {
                path: `${RoutePath.ContactLawyerPage}/*`,
                element: <ContactLawyerPage />,
              },
              {
                path: RoutePath.ContactTechnicalSupportPage,
                element: <ContactTechnicalSupportPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

// TODO: use this for the Logout action?
const Reconnect = () => {
  useEffect(() => {
    AuthService.reconnect();
  }, []);
  return null;
};

const anonymousRouter = sentryCreateBrowserRouter([
  {
    path: "*",
    // TODO: Check for good condition
    element: CookiesUtils.getAccessToken() ? <LoadingPage /> : <Reconnect />,
    errorElement: <ErrorPage />,
  },
  {
    element: <AuthenticationLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.AuthenticationErrorPage,
        element: <AuthenticationErrorPage />,
      },
      {
        path: RoutePath.PasswordResetPage,
        element: <PasswordResetPage />,
      },
      {
        path: RoutePath.PasswordResetRequestPage,
        element: <PasswordResetRequestPage />,
      },
    ],
  },
  {
    element: <AuthenticationLayout showHeader />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.LoginPage,
        element: <LoginPage />,
      },
      {
        path: RoutePath.PasswordResetCollectEmailPage,
        element: <PasswordResetCollectEmailPage />,
      },
      {
        path: RoutePath.SignUpPage,
        element: <SignUpPage />,
      },
    ],
  },
]);

const App = () => {
  const [appState, appDispatch] = useReducer(
    AppContext.reducer,
    AppContext.initialState
  );

  const [formalitiesState, formalitiesDispatch] = useReducer(
    FormalitiesContext.reducer,
    FormalitiesContext.initialState
  );

  const [decisionsState, decisionsDispatch] = useReducer(
    DecisionsContext.reducer,
    DecisionsContext.initialState
  );

  const _getUser = async () => {
    try {
      const user = await AuthService.getUserProfile();

      appDispatch({
        type: AppContext.ActionType.SetUserInfo,
        payload: user,
      });
    } catch (error) {}
  };

  const _getFormalities = async () => {
    const formalities = await FormalityService.getFormalities();
    formalitiesDispatch({
      type: FormalitiesContext.ActionType.SetFormalities,
      payload: formalities,
    });
  };

  const _getLegalEntities = async () => {
    try {
      const legalEntityIdInURL = getLegalEntityIdInURL();

      const response = await ApiService.legalEntities.getLegalEntities();
      const legalEntities = response?.data;
      if (legalEntities) {
        appDispatch({
          type: AppContext.ActionType.SetLegalEntities,
          payload: {
            currentLegalEntity:
              // Try to find a LE that match the given ID in URL
              legalEntities.find(
                (le) => String(le.id) === legalEntityIdInURL
              ) ||
              // Otherwise, take the first one in the list
              legalEntities[0],
            legalEntities: legalEntities,
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const _getSubscriptions = async () => {
    const subscriptions = await SubscriptionsService.getSubscriptions();
    appDispatch({
      type: AppContext.ActionType.SetSubscriptions,
      payload: subscriptions,
    });
  };

  const _getLegacySubscriptions = async () => {
    const legacySubscriptions =
      await LegacySubscriptionService.getSubscriptions();
    appDispatch({
      type: AppContext.ActionType.SetLegacySubscriptions,
      payload: legacySubscriptions,
    });
  };

  const _updateLSConfigCountryReferences = useCallback(async () => {
    const countryReferences = await ConfigService.getCountryReferences();
    if (countryReferences) {
      appDispatch({
        type: AppContext.ActionType.UpdateCountryReferences,
        payload: countryReferences,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!appState.user && CookiesUtils.getAccessToken()) {
      _getUser();
    }
    if (appState.user) {
      _getFormalities();
      _getLegalEntities();
      _getSubscriptions();
      _getLegacySubscriptions();
      _updateLSConfigCountryReferences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.user]);

  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <ErrorPage
          customMessage={errorData.error.message || ERROR_MESSAGES.DEFAULT}
        />
      )}
    >
      <ThemeProvider theme={defaultTheme}>
        <AppContext.Context.Provider
          value={{ state: appState, dispatch: appDispatch }}
        >
          {appState.user ? (
            <FormalitiesContext.Context.Provider
              value={{ state: formalitiesState, dispatch: formalitiesDispatch }}
            >
              <DecisionsContext.Context.Provider
                value={{ state: decisionsState, dispatch: decisionsDispatch }}
              >
                <RouterProvider router={authenticatedRouter} />
              </DecisionsContext.Context.Provider>
            </FormalitiesContext.Context.Provider>
          ) : (
            <RouterProvider router={anonymousRouter} />
          )}
        </AppContext.Context.Provider>
        <LSToastContainer />
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
