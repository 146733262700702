export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const isValidEmail = (email: string): boolean => EMAIL_REGEX.test(email);

/** Validation rules for email field using react-hook-form */
export const emailFieldValidationRulesRHF = {
  required: "Le champ ci-dessus est requis",
  pattern: {
    value: EMAIL_REGEX,
    message: "Veuillez respecter le format email __________@_____.__",
  },
};
