import { Row } from "@yolaw/ui-kit-components";
import {
  BurgerMenuFlat,
  CloseFlat,
  LegalStartLogo,
  UserFlat,
} from "@yolaw/ui-kit-icons";
import { FC, useContext, useState } from "react";
import { css, styled, useTheme } from "styled-components";

import { LayoutContext } from "contexts";
import MyAccountMenu from "./MyAccountMenu";

/*
When Sidebar is open, min-height is needed because of height shift when AJ button is not displayed:
16px padding top/bottom
+ 24px height for the biggest content element (AJ button)
+ 1px bottom border
--> 57px
*/
export const HEADER_MOBILE_MIN_HEIGHT = 57;

const Container = styled.header`
  position: sticky;
  top: 0;
  z-index: 5000;
  grid-column: header-start / header-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${HEADER_MOBILE_MIN_HEIGHT}px;
  ${({ theme }) => css`
    column-gap: ${theme.spacing.s}px;
    padding: ${theme.spacing.xs}px ${theme.spacing.s}px;
    border-bottom: 1px solid ${theme.colors.neutral.lightest};
    background-color: ${theme.colors.common.white};
  `}
`;

const LogoLink = styled.a`
  display: inline-block;
  flex-basis: 120px;
`;

const IconButton = styled.button.attrs({
  type: "button",
})`
  display: inline-block;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
`;

const MyAccountMenuContainer = styled.div`
  position: absolute;
  z-index: 5000;
  width: 100%;
  min-height: calc(100vh - ${HEADER_MOBILE_MIN_HEIGHT}px);
  top: ${HEADER_MOBILE_MIN_HEIGHT}px;
  background-color: white;
  transition: all 300ms ease;

  &.closed {
    left: -100vw;
  }

  &.open {
    left: 0;
  }
`;

const HeaderMobile: FC = () => {
  const theme = useTheme();
  const { state: layoutState, dispatch: layoutDispatch } = useContext(
    LayoutContext.Context
  );

  const { isMenuOpen } = layoutState;

  const [myAccountMenuVisible, setMyAccountMenuVisible] = useState(false);

  const onMenuOpen = () => {
    document.body.style.overflow = "hidden";
  };
  const onMenuClose = () => {
    document.body.style.overflow = "unset";
  };

  const openMyAccountMenu = () => {
    setMyAccountMenuVisible(true);
    onMenuOpen();
  };
  const closeMyAccountMenu = () => {
    setMyAccountMenuVisible(false);
    onMenuClose();
  };

  const openAppMenu = () => {
    layoutDispatch({ type: "OPEN_SIDEBAR" });
    onMenuOpen();
  };
  const closeAppMenu = () => {
    layoutDispatch({ type: "CLOSE_SIDEBAR" });
    onMenuClose();
  };

  const handleBurgerButtonClick = () => {
    openAppMenu();
  };

  const handleCloseButtonClick = () => {
    if (isMenuOpen) {
      closeAppMenu();
    }
    if (myAccountMenuVisible) {
      closeMyAccountMenu();
    }
  };

  const handleMyAccountButtonClick = () => {
    openMyAccountMenu();
  };

  return (
    <>
      <Container data-testid="app-header">
        <LogoLink href={process.env.REACT_APP_LEGALSTART_CMS_URL}>
          <LegalStartLogo height={24} />
        </LogoLink>

        {isMenuOpen || myAccountMenuVisible ? (
          <IconButton onClick={handleCloseButtonClick}>
            <CloseFlat size="16" />
          </IconButton>
        ) : (
          <Row style={{ columnGap: theme.spacing.s }}>
            <IconButton onClick={handleMyAccountButtonClick}>
              <UserFlat size="24" />
            </IconButton>

            <IconButton onClick={handleBurgerButtonClick}>
              <BurgerMenuFlat size="16" />
            </IconButton>
          </Row>
        )}
      </Container>

      <MyAccountMenuContainer
        className={myAccountMenuVisible ? "open" : "closed"}
      >
        <MyAccountMenu onItemClick={closeMyAccountMenu} />
      </MyAccountMenuContainer>
    </>
  );
};

export default HeaderMobile;
