import { Button, Text } from "@yolaw/ui-kit-components";
import { useApp } from "hooks";
import mailSvgSrc from "pages/authentication/assets/mail.svg";
import {
  Divider,
  EmailSentPageContainer,
} from "pages/authentication/components/misc-components";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserService } from "services";
import { BuiltRoutePath } from "services/router";
import { ERROR_MESSAGES } from "utils/constants";

export const UserChangeEmailInitiateRequestSentPage = () => {
  const app = useApp();
  const [isRequestingEmailChange, setIsRequestingEmailChange] = useState(false);

  useEffect(() => {
    app.setNavHeaderBackTo(BuiltRoutePath.UserProfilePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!app.user) {
    throw new Error("Non autorisé !", { cause: "missing_email" });
  }

  const requestEmailChange = async () => {
    try {
      setIsRequestingEmailChange(true);
      await UserService.changeEmailInitiate();
      setIsRequestingEmailChange(false);
    } catch (error: any) {
      toast.error(error.message || ERROR_MESSAGES.DEFAULT);
    }
  };

  return (
    <EmailSentPageContainer>
      <img src={mailSvgSrc} width={124} alt="mail" />

      <div>
        <Text fontWeightVariant="bold" type="BODY">
          Pour assurer la sécurité de votre compte, nous vous avons envoyé un
          email à votre adresse enregistrée
        </Text>
        <Text fontWeightVariant="bold" type="BODY">
          {app.user?.email}
        </Text>
      </div>

      <Text>
        Merci de suivre les instructions du message pour valider votre demande.
      </Text>

      <Divider />

      <Text>
        N’oubliez pas de vérifier vos spams si vous ne recevez pas l’email dans
        quelques minutes, sinon cliquez sur{" "}
      </Text>

      <Button
        variant="secondary"
        onClick={requestEmailChange}
        disabled={isRequestingEmailChange}
        isLoading={isRequestingEmailChange}
      >
        Renvoyer l'email
      </Button>
    </EmailSentPageContainer>
  );
};
