import { PageContentHeader } from "pages/components";
import { PageContainer, PageContentBody } from "pages/components/PageStyles";
import UserPasswordUpdateForm from "./UserPasswordUpdateForm";
import UserProfileUpdateForm from "./UserProfileUpdateForm";

export const UserProfilePage = () => {
  return (
    <PageContainer>
      <PageContentHeader title="Mes informations" />

      <PageContentBody>
        <UserProfileUpdateForm />

        <UserPasswordUpdateForm />
      </PageContentBody>
    </PageContainer>
  );
};
