import { BuiltRoutePath } from "services/router";

type RedirectionOptions = {
  backToCurrentPage?: boolean;
};

export const redirectToLoginPage = (options?: RedirectionOptions) => {
  const queryString = options?.backToCurrentPage
    ? "?next=" + encodeURIComponent(window.location.href)
    : "";
  window.location.replace(BuiltRoutePath.LoginPage + queryString);
};

export const redirectToCMS = () =>
  window.location.replace(
    process.env.REACT_APP_LEGALSTART_CMS_URL || "https://www.legalstart.fr"
  );
