import {
  Button,
  Column,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import { useIsMobile } from "hooks";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { AuthService } from "services";
import { BuiltRoutePath } from "services/router";
import styled from "styled-components";
import { EmailUtils } from "utils";
import { isValidEmail } from "utils/email";
import { getNextParam } from "../auth-utils";
import {
  Divider,
  FormFooterActionContainer,
  FormInnerContainer,
} from "../components/misc-components";

const Form = styled(FormInnerContainer).attrs({ as: "form" })``;

type FormInputs = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const emailInParams = searchParams.get("email");
  const defaultEmail =
    emailInParams && isValidEmail(emailInParams) ? emailInParams : "";

  const formMethods = useForm<FormInputs>({
    mode: "onSubmit",
    defaultValues: {
      email: defaultEmail,
      password: "",
    },
  });

  const { clearErrors, control, handleSubmit, formState, setError, watch } =
    formMethods;
  const emailValue = watch("email");
  const passwordValue = watch("password");

  const { errors, isDirty, isSubmitting, isSubmitSuccessful } = formState;

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    try {
      await AuthService.login(data.email, data.password);
      const next = getNextParam();
      // Reload with the `next`
      window.location.replace(next);
    } catch (error: any) {
      setError("root", {
        type: "custom",
        message: error.message,
      });
      // Also set custom field error to highlight the fields
      setError("email", {
        type: "custom",
        message: "",
      });
      setError("password", {
        type: "custom",
        message: "",
      });
    }
  };

  useEffect(() => {
    // Clear root error when any field is changed
    clearErrors("root");
  }, [clearErrors, emailValue, passwordValue]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title
          text="Connectez-vous"
          type={isMobile ? "H1" : "H3"}
          color="secondary.main"
        />

        <Column>
          <Controller
            control={control}
            name="email"
            rules={EmailUtils.emailFieldValidationRulesRHF}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                type="email"
                label="Votre email"
                id="email"
                placeholder="Entrez votre email"
                showLabel
                disabled={!!defaultEmail || isSubmitting}
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                {...restOfField}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{
              required: "Le champ ci-dessus est requis",
            }}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                label="Votre mot de passe"
                type="password"
                id="password"
                placeholder="Entrez votre mot de passe"
                showLabel
                disabled={isSubmitting}
                hasError={!!errors.password}
                errorMessage={errors.password?.message}
                {...restOfField}
              />
            )}
          />
        </Column>

        {errors.root && (
          <Text
            type="small"
            text={errors.root.message}
            color="error.dark"
            fontWeightVariant="bold"
          />
        )}

        <FormFooterActionContainer>
          <Link
            to={
              (emailValue && isValidEmail(emailValue)
                ? BuiltRoutePath.PasswordResetRequestPage
                : BuiltRoutePath.PasswordResetCollectEmailPage) +
              window.location.search
            }
            state={{
              email: emailValue,
            }}
          >
            <Text
              color={isSubmitting ? "neutral.light" : undefined}
              fontWeightVariant="bold"
            >
              Mot de passe oublié ?
            </Text>
          </Link>

          <Button
            type="submit"
            variant="primary"
            disabled={!isDirty || isSubmitting || isSubmitSuccessful}
            isLoading={isSubmitting}
          >
            Me connecter
          </Button>
        </FormFooterActionContainer>
      </Form>

      <Divider />

      <Text>
        Pas encore de compte ?{" "}
        <Link to={BuiltRoutePath.SignUpPage}>Créer mon compte</Link>
      </Text>
    </>
  );
};
