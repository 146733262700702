import { ArrowRightFlat } from "@yolaw/ui-kit-icons";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  column-gap: ${(props) => props.theme.spacing.xxxs}px;
  padding: ${(props) => props.theme.spacing.xs}px;
  border: 0;
  color: inherit;
  background: transparent;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
  text-decoration: none;
  cursor: pointer;

  > svg {
    rotate: 180deg;
    flex-shrink: 0;
  }
`;

type BackButtonProps = {
  label?: string;
  to?: LinkProps["to"];
} & Omit<LinkProps, "to">;

const BackButton = ({
  label = "Retour",
  to = "..",
  ...linkProps
}: BackButtonProps) => {
  return (
    <StyledLink to={to} {...linkProps} >
      <ArrowRightFlat size="20" /> {label}
    </StyledLink>
  );
};

export default BackButton;
