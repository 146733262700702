import { Button, Checkbox, Row, Text, Title } from "@yolaw/ui-kit-components";
import { useApp, useIsMobile } from "hooks";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthService } from "services";
import { BuiltRoutePath } from "services/router";
import styled, { css } from "styled-components";
import { getNextParam } from "../auth-utils";
import NewPasswordFieldset, {
  NewPasswordFieldsetInputs,
  PasswordForm,
} from "../components/NewPasswordFieldset";

const EmailOptInField = styled(Row)`
  ${({ theme }) => css`
    && {
      align-items: flex-start;
      column-gap: ${theme.spacing.xxxxs}px;
    }
  `};
`;

type FormInputs = NewPasswordFieldsetInputs & {
  emailOptIn: boolean;
};

export const SetPasswordPage = () => {
  const isMobile = useIsMobile();
  const { user, setUserInfo } = useApp();
  const navigate = useNavigate();

  const formMethods = useForm<FormInputs>({
    mode: "onTouched",
    criteriaMode: "all",
    defaultValues: {
      password: "",
      confirmPassword: "",
      emailOptIn: !!user?.ok_for_emails,
    },
  });

  const { control, handleSubmit, formState, setError } = formMethods;

  const { dirtyFields, errors, isSubmitting } = formState;

  const submitPassword = async (password: string) => {
    const { success, error } = await AuthService.initialPassword({
      password,
      next: getNextParam(),
    });

    if (error) {
      const e = error as any;

      setError(
        "root",
        {
          type: "custom",
          message:
            e?.password ??
            "Une erreur est survenue lors de la création de votre compte.",
        },
        { shouldFocus: true }
      );
    }

    if (success) {
      // User who need set password is supposed to be a new user
      // thus email verification is the next step
      navigate(BuiltRoutePath.EmailVerificationPage + window.location.search, {
        replace: true,
        state: {
          disableInitialRequest: true,
        },
      });
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    if (dirtyFields.emailOptIn) {
      const newUserData = await AuthService.patchUserProfile({
        ok_for_emails: data.emailOptIn,
      });
      if (newUserData) {
        setUserInfo(newUserData);
      }
    }

    await submitPassword(data.password);
  };

  useEffect(() => {
    if (user?.has_set_password) {
      // Don't allow user already set password to stay at this page
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.has_set_password]);

  return (
    <FormProvider {...formMethods}>
      <PasswordForm onSubmit={handleSubmit(onSubmit)}>
        <Title
          text="Veuillez créer votre mot de passe"
          type={isMobile ? "H1" : "H3"}
          color="secondary.main"
        />

        <NewPasswordFieldset
          labels={{
            password: "Votre mot de passe",
            confirmPassword: "Confirmez votre mot de passe",
          }}
        />

        <EmailOptInField>
          <Controller
            control={control}
            name="emailOptIn"
            render={({ field: { ref, value, ...restOfField } }) => (
              <Checkbox
                {...restOfField}
                id="emailOptIn"
                checked={value}
                value={JSON.stringify(value)}
              />
            )}
          />
          <label htmlFor="emailOptIn">
            <Text type="XSMALL" color="neutral.darker">
              J’accepte que Legalstart m’envoie des informations sur des
              promotions ou des services fournis par Legalstart (promis, que des
              informations utiles, pas de spam !)
            </Text>
          </label>
        </EmailOptInField>

        {errors.root && (
          <Text
            type="XSMALL"
            text={errors.root.message}
            color="error.dark"
            fontWeightVariant="bold"
          />
        )}

        <Button
          type="submit"
          variant="primary"
          text="Valider"
          disabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </PasswordForm>
    </FormProvider>
  );
};
