import { PhoneNumberInputField, TextField } from "@yolaw/ui-kit-components";
import { FieldGroupRow, Fieldset } from "components/FormStyles";
import { AppContext } from "contexts";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useContext } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { EmailUtils } from "utils";

export type TUserInformationInputs = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
};

type ValidationRules = {
  [key in keyof TUserInformationInputs]?: UseControllerProps["rules"];
};

type UserInformationFieldsetProps = {
  validationRules?: ValidationRules;
  showPhone?: boolean;
  showEmail?: boolean;
};

export const UserInformationFieldset = ({
  validationRules,
  showPhone = true,
  showEmail = true,
}: UserInformationFieldsetProps) => {
  const { state: appState } = useContext(AppContext.Context);

  const {
    configs: { countryReferences },
  } = appState;

  const { control, formState } = useFormContext<TUserInformationInputs>();

  const { errors } = formState;

  return (
    <Fieldset>
      <FieldGroupRow>
        <Controller
          control={control}
          name="firstName"
          rules={{ ...validationRules?.firstName }}
          render={({ field: { ref, ...restOfField } }) => (
            <TextField
              label="Prénom"
              placeholder="Mon prénom"
              showLabel
              hasError={!!errors.firstName}
              errorMessage={errors.firstName?.message}
              {...restOfField}
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          rules={{ ...validationRules?.lastName }}
          render={({ field: { ref, ...restOfField } }) => (
            <TextField
              label="Nom"
              placeholder="Mon nom"
              showLabel
              hasError={!!errors.lastName}
              errorMessage={errors.lastName?.message}
              {...restOfField}
            />
          )}
        />
      </FieldGroupRow>

      {showPhone && (
        <Controller
          control={control}
          name="phoneNumber"
          rules={{
            /** If there is a value, it must be a valid phone number.
             * Otherwise, skip the validation by returning `true` value.
             * */
            validate: (value: string) =>
              value ? isValidPhoneNumber(value, "FR") : true,
            ...validationRules?.phoneNumber,
          }}
          render={({ field: { ref, ...restOfField } }) => (
            <PhoneNumberInputField
              countryOptions={countryReferences}
              label="Téléphone"
              id="phone-number-input"
              placeholder="06 00 00 00 00"
              disableInternalValidation={
                errors.phoneNumber?.type === "required"
              }
              error={errors.phoneNumber?.message}
              {...restOfField}
            />
          )}
        />
      )}

      {showEmail && (
        <Controller
          control={control}
          name="email"
          rules={{
            pattern: {
              value: EmailUtils.EMAIL_REGEX,
              message: "Cette adresse email est invalide.",
            },
            ...validationRules?.email,
          }}
          render={({ field: { ref, ...restOfField } }) => (
            <TextField
              type="email"
              label="Email"
              placeholder="Mon email"
              showLabel
              hasError={!!errors.email}
              errorMessage={errors.email?.message}
              {...restOfField}
            />
          )}
        />
      )}
    </Fieldset>
  );
};

export default UserInformationFieldset;
