import { datadogRum } from "@datadog/browser-rum";

export const initializeDatadog = () => {
  const hostname = window.location.hostname;
  const env =
    hostname === "app.legalstart.fr" ? "production" : hostname.split("-app.legalstart.fr")[0];

  const coreApiUrl = process.env.REACT_APP_CORE_API_URL ?? "";
  const webAppDomain = process.env.REACT_APP_WEB_APP_DOMAIN ?? "";

  datadogRum.init({
    applicationId: "cc44ca26-db03-4448-aae0-513abb5bd0d3",
    clientToken: "pubf3d60cac0d2f4621e0e2da62126527da",
    site: "datadoghq.eu",
    service: "ls-userspace",
    env,
    sessionSampleRate: 5,
    sessionReplaySampleRate: 1,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [coreApiUrl, webAppDomain],
  });
};
