export const LIST_MAX_VISIBLE_ELEMENTS = 3;

export const SUPPORT_CONTACT = {
  PHONE_NUMBER: "01 76 46 02 66",
};

export const ERROR_MESSAGES = {
  DEFAULT: "Une erreur est survenue. Merci de réessayer.",
};

export const PASSWORD_MIN_LENGTH = 12; // characters

export const TOC_LINKS = {
  CGU: "https://www.legalstart.fr/corp/cgu/",
  PRIVACY: "https://www.legalstart.fr/corp/vie-privee/",
};
