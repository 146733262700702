import * as Sentry from "@sentry/react";
import { ProjectContextState as ZenProjectContextState } from "pages/zen/context";
import React from "react";
import { LinkProps } from "react-router-dom";
import { GetInvoicesResponseData } from "types/invoices";
import LegacySubscription from "types/legacy-subscriptions";
import { LegalEntity } from "types/legal-entities";
import { Subscription } from "types/subscriptions";
import LSUser from "types/user";

const defaultCountryOptions: CountryReferenceType[] = [
  {
    countryIso2Code: "FR",
    flagEmoji: "🇫🇷",
    intlDialCode: "+33",
    nameFr: "France",
  },
  {
    countryIso2Code: "DE",
    flagEmoji: "🇩🇪",
    intlDialCode: "+49",
    nameFr: "Allemagne",
  },
  {
    countryIso2Code: "IT",
    flagEmoji: "🇮🇹",
    intlDialCode: "+39",
    nameFr: "Italie",
  },
  {
    countryIso2Code: "DZ",
    flagEmoji: "🇩🇿",
    intlDialCode: "+213",
    nameFr: "Algérie",
  },
  {
    countryIso2Code: "PT",
    flagEmoji: "🇵🇹",
    intlDialCode: "+351",
    nameFr: "Portugal",
  },
];

export enum ModalName {
  AJSubscriptionPayment = "AJ_SUBSCRIPTION_PAYMENT_MODAL",
  CSSubscriptionPayment = "CS_SUBSCRIPTION_PAYMENT_MODAL",
  ZenSubscriptionPayment = "ZEN_SUBSCRIPTION_PAYMENT_MODAL",
  ZenWaitingForSiren = "ZEN_WAITING_FOR_SIREN",
  RecordsOrderTitleRecordsSuccess = "RECORDS_ODER_TITLE_RECORDS_SUCCESS",
  ERecordsWaitingForSiren = "E_RECORDS_WAITING_FOR_SIREN",
}

export type OpeningModal = {
  name: ModalName;
  /** Mostly used for tracking events to identify the source of opener  */
  openedBy: {
    /** What is the app/feature opens the modal? */
    context: string;
    /** Where is the element triggered modal opening placed? */
    from: string;
  };
};

type AppContextState = {
  admin: LSUser.Info | null;
  currentLegalEntity: LegalEntity | null;
  invoices: GetInvoicesResponseData;
  legalEntities: LegalEntity[] | null;
  user: LSUser.Info | null;
  configs: {
    countryReferences: CountryReferenceType[];
  };
  openingModal: OpeningModal | null;
  subscriptions: Subscription[];
  legacySubscriptions: LegacySubscription.SubscriptionItem[];

  /** App navigation related states */
  navigation: {
    /** Store the "to" value for the back button in the app header */
    headerBackTo?: LinkProps['to'];
  };

  _cache: {
    zenContextState: Partial<ZenProjectContextState> | null;
  };
};

const initialState: AppContextState = {
  admin: null,
  currentLegalEntity: null,
  invoices: {
    web_invoices: [],
    billing_documents: {},
  },
  legalEntities: null,
  user: null,
  configs: {
    countryReferences: defaultCountryOptions,
  },
  openingModal: null,
  subscriptions: [],
  legacySubscriptions: [],

  navigation: {},

  _cache: {
    zenContextState: null,
  },
};

enum ActionType {
  CloseModal,
  OpenModal,
  SetCurrentLegalEntity,
  SetInvoices,
  SetLegalEntities,
  SetAdminInfo,
  SetUserInfo,
  SetSubscriptions,
  SetLegacySubscriptions,
  SetNavHeaderBackTo,
  UpdateCountryReferences,
  UpdateCacheStore,
}

type AppDispatchAction =
  | {
      type: ActionType.CloseModal;
    }
  | {
      type: ActionType.OpenModal;
      payload: OpeningModal;
    }
  | {
      type: ActionType.SetAdminInfo;
      payload: AppContextState["admin"];
    }
  | {
      type: ActionType.SetCurrentLegalEntity;
      payload: LegalEntity;
    }
  | {
      type: ActionType.SetInvoices;
      payload: AppContextState["invoices"];
    }
  | {
      type: ActionType.SetLegalEntities;
      payload: Pick<AppContextState, "currentLegalEntity" | "legalEntities">;
    }
  | {
      type: ActionType.SetUserInfo;
      payload: AppContextState["user"];
    }
  | {
      type: ActionType.SetSubscriptions;
      payload: Subscription[];
    }
  | {
      type: ActionType.SetLegacySubscriptions;
      payload: LegacySubscription.SubscriptionItem[];
    }
  | {
      type: ActionType.SetNavHeaderBackTo;
      payload: AppContextState["navigation"]["headerBackTo"];
    }
  | {
      type: ActionType.UpdateCountryReferences;
      payload: CountryReferenceType[];
    }
  | {
      type: ActionType.UpdateCacheStore;
      payload: Partial<AppContextState["_cache"]>;
    };

const reducer = (
  state: AppContextState,
  action: AppDispatchAction
): AppContextState => {
  switch (action.type) {
    case ActionType.CloseModal:
      if (state.openingModal) {
        const modalElement = document.getElementById(state.openingModal.name);
        modalElement?.classList.remove("opened");
      }
      return {
        ...state,
        openingModal: null,
      };
    case ActionType.OpenModal:
      const modalElement = document.getElementById(action.payload.name);
      modalElement?.classList.add("opened");
      return {
        ...state,
        openingModal: action.payload,
      };
    case ActionType.SetAdminInfo:
      Sentry.setContext("admin", action.payload);
      return {
        ...state,
        admin: action.payload,
      };
    case ActionType.SetCurrentLegalEntity:
      Sentry.setContext("currentLegalEntity", action.payload);
      return {
        ...state,
        currentLegalEntity: action.payload,
      };
    case ActionType.SetInvoices:
      return {
        ...state,
        invoices: action.payload,
      };
    case ActionType.SetLegalEntities:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.SetUserInfo:
      const { id, email } = action.payload || {};
      Sentry.setUser(action.payload ? { id, email } : null);
      return {
        ...state,
        user: action.payload,
      };
    case ActionType.SetSubscriptions:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case ActionType.SetLegacySubscriptions:
      return {
        ...state,
        legacySubscriptions: action.payload,
      };
    case ActionType.SetNavHeaderBackTo:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          headerBackTo: action.payload,
        },
      };
    case ActionType.UpdateCountryReferences:
      return {
        ...state,
        configs: {
          ...state.configs,
          countryReferences: action.payload,
        },
      };
    case ActionType.UpdateCacheStore:
      return {
        ...state,
        _cache: {
          ...state._cache,
          ...action.payload,
        },
      };
    default:
      throw new Error("[AppContext] unknown action type");
  }
};

type AuthContextType = {
  state: AppContextState;
  dispatch: React.Dispatch<AppDispatchAction>;
};

const Context = React.createContext<AuthContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "AppContext";

const AppContext = {
  ActionType,
  Context,
  initialState,
  reducer,
};

export default AppContext;
