import { Button, Text } from "@yolaw/ui-kit-components";
import { useApp } from "hooks";
import mailSvgSrc from "pages/authentication/assets/mail.svg";
import {
  Divider,
  EmailSentPageContainer,
} from "pages/authentication/components/misc-components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { UserService } from "services";
import { BuiltRoutePath } from "services/router";
import { ERROR_MESSAGES } from "utils/constants";

export const UserChangeEmailConfirmRequestSentPage = () => {
  const app = useApp();
  const { state: locationState } = useLocation();
  const { newEmailAddress, encodedEmail } = locationState || {};
  const [isConfirmingEmailChange, setIsConfirmingEmailChange] = useState(false);

  useEffect(() => {
    app.setNavHeaderBackTo(BuiltRoutePath.UserProfilePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!newEmailAddress || !encodedEmail) {
    throw new Error("Non autorisé !", { cause: "missing_email" });
  }

  const confirmEmailChange = async () => {
    try {
      setIsConfirmingEmailChange(true);
      await UserService.changeEmailConfirm({
        current_encoded_email: encodedEmail,
        new_email_address: newEmailAddress,
      });
      setIsConfirmingEmailChange(false);
    } catch (error: any) {
      toast.error(error.message || ERROR_MESSAGES.DEFAULT);
    }
  };

  return (
    <EmailSentPageContainer>
      <img src={mailSvgSrc} width={124} alt="mail" />

      <div>
        <Text fontWeightVariant="bold" type="BODY">
          Un email avec un lien de vérification a été envoyé à votre nouvelle
          adresse email
        </Text>
        <Text fontWeightVariant="bold" type="BODY">
          {newEmailAddress}
        </Text>
      </div>
      <Divider />

      <Text>
        N’oubliez pas de vérifier vos spams si vous ne recevez pas l’email dans
        quelques minutes, sinon cliquez sur
      </Text>

      <Button
        variant="secondary"
        onClick={confirmEmailChange}
        disabled={isConfirmingEmailChange}
        isLoading={isConfirmingEmailChange}
      >
        Renvoyer l'email
      </Button>
    </EmailSentPageContainer>
  );
};
