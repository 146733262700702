import { toast } from "react-toastify";
import { ApiService } from "services";
import LSUser from "types/user";
import { CookiesUtils } from "utils";
import { ERROR_MESSAGES } from "utils/constants";
import { redirectToLoginPage } from "./redirection";

/** Store tokens in cookies */
export const onAuthChange = (accessToken: string, refreshToken: string) => {
  CookiesUtils.setAccessToken(accessToken);
  CookiesUtils.setRefreshToken(refreshToken);
};

const login = async (email: string, password: string) => {
  try {
    const response = await ApiService.authentication.login(email, password);

    if (response?.status === 200 && response?.data) {
      const { access, refresh } = response.data;

      // Set new tokens to cookies
      onAuthChange(access, refresh);

      return { access, refresh };
    }

    // Return error for displaying on UI
    throw new Error(response?.data?.error || "Mot de passe incorrect.");
  } catch (error) {
    throw error;
  }
};

const changePassword = async (newPassword: string) => {
  try {
    const response = await ApiService.users.changePassword(newPassword);

    if (response?.status === 200 && response?.data) {
      const { access, refresh } = response.data;

      // Set new tokens to cookies
      onAuthChange(access, refresh);

      return { success: { access, refresh } };
    }

    // Return error for displaying on UI
    return { error: response?.data };
  } catch (error) {
    throw error;
  }
};

const initialPassword = async (data: InitialPasswordData) => {
  try {
    const response = await ApiService.users.initialPassword(data);

    if (response?.status === 200 && response?.data) {
      const { access, refresh } = response.data;

      // Set new tokens to cookies
      onAuthChange(access, refresh);

      return { success: { access, refresh } };
    }

    // Return error for displaying on UI
    return { error: response?.data };
  } catch (error) {
    throw error;
  }
};

const clearAuthInfo = () => {
  CookiesUtils.removeAuthTokens();
  CookiesUtils.removeAdminAuthTokens();
};

const getUserProfile = async (): Promise<LSUser.Info | null> => {
  try {
    const response = await ApiService.users.getMe();
    return response?.data || null;
  } catch (error) {
    throw error;
  }
};

const patchUserProfile = async (data: Partial<LSUser.Info>) => {
  try {
    const response = await ApiService.users.patchMe(data);
    return response?.data || null;
  } catch (error) {
    toast.error(
      "Une erreur est survenue lors de la mise à jour de votre profil."
    );
    throw error;
  }
};

const reconnect = () => {
  clearAuthInfo();
  redirectToLoginPage({ backToCurrentPage: true });
};

const passwordResetRequest = async (data: PasswordResetRequestData) => {
  try {
    const response = await ApiService.users.passwordResetRequest(data);

    if (
      response?.status === 204 ||
      (response?.status === 403 && response?.data?.redirect_url)
    ) {
      return response.data;
    } else {
      throw Error(ERROR_MESSAGES.DEFAULT);
    }
  } catch (error) {
    throw error;
  }
};

const passwordResetSave = async (data: PasswordResetSaveData) => {
  try {
    const response = await ApiService.users.passwordResetSave(data);

    if (
      (response?.status === 200 || response?.status === 403) &&
      response?.data?.redirect_url
    ) {
      return response.data;
    } else {
      throw Error(response?.data?.error || ERROR_MESSAGES.DEFAULT);
    }
  } catch (error) {
    throw error;
  }
};

const AuthService = {
  login,
  changePassword,
  initialPassword,
  clearAuthInfo,
  getUserProfile,
  patchUserProfile,
  reconnect,
  passwordResetRequest,
  passwordResetSave,
};

export default AuthService;
