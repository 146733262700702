import { Button, Column, Text } from "@yolaw/ui-kit-components";
import { useSearchParams } from "react-router-dom";
import { ERROR_MESSAGES } from "utils/constants";
import warningIconSrc from "./assets/warning 1.svg";
import styled, { css } from "styled-components";

const Container = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.m}px;
      align-items: center;
    }
  `}
`;

const AuthenticationErrorPage = () => {
  const [searchParams] = useSearchParams();

  const errorMessage =
    searchParams.get("error_message") || ERROR_MESSAGES.DEFAULT;
  const redirectionURL = searchParams.get("next") || "/";

  const handleButtonClick = () => {
    window.location.replace(redirectionURL);
  };

  return (
    <Container>
      <img src={warningIconSrc} alt="warning" width={86} />
      <Column style={{ alignItems: "center", textAlign: "center" }}>
        <Text>{errorMessage}</Text>
        <Button onClick={handleButtonClick}>Continuer</Button>
      </Column>
    </Container>
  );
};

export default AuthenticationErrorPage;
