import {
  Button,
  Column,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import { useApp, useIsMobile, useUser } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import {
  FormFooterActionContainer,
  FormInnerContainer,
} from "pages/authentication/components/misc-components";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { UserService } from "services";
import { BuiltRoutePath } from "services/router";
import { EmailUtils } from "utils";
import { ERROR_MESSAGES } from "utils/constants";

type FormInputs = {
  email: string;
};

export const UserChangeEmailConfirmPage = () => {
  const app = useApp();
  const user = useUser();
  const isMobile = useIsMobile();
  const { navigateTo } = useAppRoutes();
  const [searchParams] = useSearchParams();

  const formMethods = useForm<FormInputs>({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
  });
  const { control, formState, handleSubmit, setError } = formMethods;
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    app.setNavHeaderBackTo(BuiltRoutePath.UserProfilePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const encodedEmail = searchParams.get("encoded_email");
  if (!encodedEmail) {
    throw new Error("Non autorisé !", { cause: "missing_encoded_email" });
  }

  const onSubmit = async (data: FormInputs) => {
    try {
      const newEmailAddress = data.email;

      await UserService.changeEmailConfirm({
        current_encoded_email: encodedEmail,
        new_email_address: newEmailAddress,
      });

      navigateTo(BuiltRoutePath.UserEmailChangeConfirmRequestSentPage, {
        replace: true,
        state: { newEmailAddress, encodedEmail },
      });
    } catch (error: any) {
      setError("email", { message: error.message || ERROR_MESSAGES.DEFAULT });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInnerContainer>
        <Column>
          <Title
            text="Modifiez votre email"
            type={isMobile ? "H1" : "H3"}
            color="secondary.dark"
          />

          <Text>
            Renseignez le nouvel email avec lequel vous souhaitez vous connecter
            à votre compte.
          </Text>

          <TextField
            type="email"
            showLabel
            label="Email actuel"
            value={user.email}
            disabled
          />

          <Controller
            control={control}
            name="email"
            rules={EmailUtils.emailFieldValidationRulesRHF}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                autoFocus
                type="email"
                showLabel
                label="Nouvel email"
                placeholder="Entrez votre nouvel email"
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                {...restOfField}
              />
            )}
          />
        </Column>

        <FormFooterActionContainer>
          <Link to={BuiltRoutePath.UserProfilePage} replace>
            Annuler
          </Link>

          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            {isMobile ? "Modifier l'email" : "Modifier"}
          </Button>
        </FormFooterActionContainer>
      </FormInnerContainer>
    </form>
  );
};
